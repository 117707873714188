<template>
  <div class="row">
    <div class="col-12">
    <div class="title-nav">
      <h2>{{ $t("PackageDetails") }}</h2>
      <div class="d-flex align-items-center">
        <Show_Price
          v-if="OrderData?.status?.id == 5"
          :price_offers="OrderData?.price_offers"
        />
      </div>
    </div>
    <div class="nested-title mb-3">
      {{
        this.$i18n.locale == "ar"
          ? `تفاصيل الباقة الخاصة بشركتك`
          : `Details of your company's package`
      }}
    </div>
    <div class="col-md-6 package-item mb-3" :style="packageBorder">
      <div
        :class="['title-color','position-relative']"
        :style="packageTitleBg"
      >
        {{ packageDetails.name }}
      </div>
      <div
        class="accordion-item"
        v-for="(serv, index) in packageDetails.services"
      >
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${index}`"
            aria-expanded="true"
            :aria-controls="`collapse${index}`"
          >
            <svg
              style="margin-inline-end: 12px"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.1136 13.725L0.263593 7.62349C-0.0878643 7.25692 -0.0878643 6.66256 0.263593 6.29596L1.53636 4.96843C1.88781 4.60182 2.4577 4.60182 2.80915 4.96843L6.75 9.07869L15.1908 0.274927C15.5423 -0.0916425 16.1122 -0.0916425 16.4636 0.274927L17.7364 1.60246C18.0879 1.96903 18.0879 2.56338 17.7364 2.92998L7.3864 13.7251C7.0349 14.0916 6.46506 14.0916 6.1136 13.725Z"
                fill="#052C57"
              />
            </svg>
            {{ serv.sub_category_name }}
            <svg
              class="plus"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8571 9.14286H9.14286V14.8571C9.14286 15.1602 9.02245 15.4509 8.80812 15.6653C8.59379 15.8796 8.30311 16 8 16C7.6969 16 7.40621 15.8796 7.19188 15.6653C6.97755 15.4509 6.85714 15.1602 6.85714 14.8571V9.14286H1.14286C0.839753 9.14286 0.549063 9.02245 0.334735 8.80812C0.120408 8.59379 0 8.30311 0 8C0 7.6969 0.120408 7.40621 0.334735 7.19188C0.549063 6.97755 0.839753 6.85714 1.14286 6.85714H6.85714V1.14286C6.85714 0.839753 6.97755 0.549062 7.19188 0.334735C7.40621 0.120407 7.6969 0 8 0C8.30311 0 8.59379 0.120407 8.80812 0.334735C9.02245 0.549062 9.14286 0.839753 9.14286 1.14286V6.85714H14.8571C15.1602 6.85714 15.4509 6.97755 15.6653 7.19188C15.8796 7.40621 16 7.6969 16 8C16 8.30311 15.8796 8.59379 15.6653 8.80812C15.4509 9.02245 15.1602 9.14286 14.8571 9.14286Z"
                fill="#052C57"
              />
            </svg>
            <svg
              class="close"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0406 11.6571L6 7.61648L1.95939 11.6571C1.74506 11.8714 1.45437 11.9918 1.15127 11.9918C0.848163 11.9918 0.557474 11.8714 0.343146 11.6571C0.128819 11.4428 0.00841052 11.1521 0.00841086 10.849C0.00841052 10.5459 0.128819 10.2552 0.343147 10.0408L4.38376 6.00023L0.343146 1.95962C0.128818 1.74529 0.00841078 1.4546 0.00841048 1.1515C0.00841085 0.848395 0.128818 0.557705 0.343146 0.343378C0.557473 0.12905 0.848163 0.00864293 1.15127 0.0086429C1.45437 0.00864286 1.74506 0.129051 1.95939 0.343378L6 4.38399L10.0406 0.343378C10.2549 0.129051 10.5456 0.00864248 10.8487 0.00864252C11.1518 0.00864255 11.4425 0.12905 11.6569 0.343378C11.8712 0.557705 11.9916 0.848395 11.9916 1.1515C11.9916 1.4546 11.8712 1.74529 11.6569 1.95962L7.61624 6.00023L11.6569 10.0408C11.8712 10.2552 11.9916 10.5459 11.9916 10.849C11.9916 11.1521 11.8712 11.4428 11.6569 11.6571C11.4425 11.8714 11.1518 11.9918 10.8487 11.9918C10.5456 11.9918 10.2549 11.8714 10.0406 11.6571Z"
                fill="#052C57"
              />
            </svg>
          </button>
        </h2>

        <div
          :id="`collapse${index}`"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
        >
          <div class="accordion-body">
            <ul>
              <li v-for="n in serv.services">{{ n.title }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="col-md-6">
      <div class="item d-flex align-items-center justify-content-between">
        <div class="title">{{ $t("commercial_registration") }}</div>
        <div class="value mt-0" style="font-size: 14px">
          {{ CurrentCompany.commercial_register }}
        </div>
      </div>
      <div class="item d-flex align-items-center justify-content-between" v-if="CurrentCompany.commercial_register_start_date">
        <div class="title">{{ $t("Commercial Start Date") }}</div>
        <div class="value mt-0" style="font-size: 14px">
          {{
            CurrentCompany.commercial_register_start_date
              ? formatDate(CurrentCompany.commercial_register_start_date)
              : ""
          }}
        </div>
      </div>
      <div class="item d-flex align-items-center justify-content-between" v-if="CurrentCompany?.commercial_register_end_date">
        <div class="title">{{ $t("Commercial End Date") }}</div>
        <div class="value mt-0" style="font-size: 14px">
          {{
            CurrentCompany.commercial_register_end_date
              ? formatDate(CurrentCompany.commercial_register_end_date)
              : ""
          }}
        </div>
      </div>
      <div v-if="subscription?.start_date" class="item d-flex align-items-center justify-content-between">
        <div class="title">{{ $t("Activation date") }}</div>
        <div class="value mt-0" style="font-size: 14px">
          {{


            subscription?.start_date
              ? formatDate(subscription?.start_date)
              : ""

          }}
        </div>
      </div>
      <div v-if="subscription?.end_date" class="item d-flex align-items-center justify-content-between">
        <div class="title">{{ $t("End_date") }}</div>
        <div class="value mt-0" style="font-size: 14px">
          {{

              subscription?.end_date
              ? formatDate(subscription?.end_date)
              : ""


          }}
        </div>
      </div>
      <div v-if="subscription?.status" class="item d-flex align-items-center justify-content-between">
        <div class="title">{{ $t("Subscription Status") }}</div>
        <div class="value mt-0" style="font-size: 14px" v-if="subscription?.status">
          <span v-if="['مفعل','active'].includes(subscription?.status.toLowerCase())" class="text-success">{{ subscription?.status }} </span>
          <span v-else-if="['almost_expired','أوشكت على انتهاء الصلاحية'].includes(subscription?.status.toLowerCase())" class="text-danger">{{ subscription?.status }} </span>
          <span v-else-if="['terminated','ملغية'].includes(subscription?.status.toLowerCase())" class="text-dark">{{ subscription?.status }} </span>
          <span v-else class="text-warning">{{ subscription?.status }} </span>
        </div>
      </div>

    </div>
    <div class="nested-title mb-3">
      {{
        this.$i18n.locale == "ar"
          ? `تفاصيل طلب الباقة`
          : `Package order details`
      }}
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="title">
            {{
              this.$i18n.locale == "ar" ? `عدد الموظفين` : `Number of Employees`
            }}
          </div>
          <div class="value mt-0" style="font-size: 14px">
            {{ number_of_employees }}
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="title">
            {{
              this.$i18n.locale == "ar" ? `عدد السجلات` : `Number of Records`
            }}
          </div>
          <div class="value mt-0" style="font-size: 14px">
            {{ number_of_commercial_registers }}
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="title">
            {{ this.$i18n.locale == "ar" ? `خطة الدفع` : `Payment plan` }}
          </div>
          <div class="value mt-0" style="font-size: 14px">
            {{ $t(`${plan}`) }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" v-if="is_price_visible == 1">
      <div class="item">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="title">
            {{ this.$i18n.locale == "ar" ? `السعر` : `Price` }}
          </div>
          <div
            class="value mt-0"
            style="font-size: 14px"
            v-if="plan == 'annual'"
          >
            {{ packageDetails.annual_price }} {{ $t("SAR") }}
          </div>
          <div
            class="value mt-0"
            style="font-size: 14px"
            v-if="plan == 'semi-annual'"
          >
            {{ packageDetails.semi_annual_price }} {{ $t("SAR") }}
          </div>
          <div
            class="value mt-0"
            style="font-size: 14px"
            v-if="plan == 'quarterly'"
          >
            {{ packageDetails.quarterly_price }} {{ $t("SAR") }}
          </div>
          <div
            class="value mt-0"
            style="font-size: 14px"
            v-if="plan == 'monthly'"
          >
            {{ packageDetails.monthly_price }} {{ $t("SAR") }}
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between mb-3"
          v-if="packageDetails.discount != 0"
        >
          <div class="title">
            {{ this.$i18n.locale == "ar" ? `الخصم ` : `Discount` }}
          </div>
          <div class="value mt-0" style="font-size: 14px">
            {{ packageDetails.discount * 100 }} %
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between mb-3"
          v-if="packageDetails.tax != 0"
        >
          <div class="title">
            {{ this.$i18n.locale == "ar" ? `الضريبة` : `Tax` }}
          </div>
          <div class="value mt-0" style="font-size: 14px">
            {{ packageDetails.tax * 100 }} %
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="title">
            {{ this.$i18n.locale == "ar" ? `الاجمالي` : `Total Amount` }}
          </div>
          <div class="value mt-0" style="font-size: 14px">
            {{ calculatedAmount }} {{ $t("SAR") }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-6"
      v-if="OrderInfo?.attributes?.length > 0"
      v-for="(item, index) in OrderInfo?.attributes"
      :key="index"
    >
      <div class="item">
        <div class="title">{{ item.name }}</div>
        <div class="value" v-if="item.type == 'text' || item.type == 'date'">
          {{ attribute_values[index].attribute_value }}
        </div>
        <div class="value" v-if="item.type == 'select'">
          {{ SelectValue(item, index) }}
        </div>
        <div class="value" v-if="item.type == 'checkbox'">
          {{ SelectValue(item, index) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      index: 1,
      CurrentCompany: cookie.get("Companydata"),
      totalAmount: 0,
    };
  },

  props: {
    subscription:Object,
    OrderInfo: Object,
    packageDetails: Object,
    number_of_employees: Number,
    number_of_commercial_registers: Number,
    attribute_values: Array,
    plan: String,
    amount: Number,
    is_price_visible: Number,
  },
  computed: {
    calculatedAmount() {
      return this.amount;
      // if (this.packageDetails.discount > 0) {
      //   price -= price * this.packageDetails.discount;
      // }
      // if (this.packageDetails.tax > 0) {
      //   price += price * this.packageDetails.tax;
      // }
      //
      // return Math.round(price);
    },
    packageTitleBg() {
      const color1 = this.packageDetails?.color_1 || ['#0349A8'];
      const color2 = this.packageDetails?.color_2 || ['#0349A8'];
      return { background: `linear-gradient(93deg, ${color1} 22.52%, ${color2} 48.02%)` };
    },
    packageBorder(){
      const color2 = this.packageDetails?.color_2 || ['#0349A8'];
      return { border:`2px solid${color2}`};
    }
  },

  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear(); // Full year
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits for day

      return `${year}/${month}/${day}`;
    },
    SelectValue(atrr, index) {
      if (atrr.type == "select") {
        let valueSelect = "";
        atrr.attribute_values.forEach((element) => {
          if (element.id == this.attribute_values[index].attribute_value_id) {
            valueSelect = element.value;
          }
        });
        return valueSelect;
      }
      if (atrr.type == "checkbox") {
        let valueCheckbox = "";
        atrr.attribute_values.forEach((element) => {
          if (
            this.attribute_values[index].attribute_value_id.includes(element.id)
          ) {
            valueCheckbox.push(element.value);
          }
        });
        return valueCheckbox;
      }
    },
  },
  mounted() {
    this.totalAmount = this.calculatedAmount;
  },
};
</script>
